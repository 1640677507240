import React, { useState, useEffect, useRef } from "react";
import useInterval from "../useInterval";
import FA from "react-fontawesome";

const getRandomInt = (max) => {
  return Math.floor(Math.random() * (max + 1));
};

const prompts = [
  "Would you rather eat",
  "If you were stranged on a desert islands with only one of these, which would you pick",
  "One of these will be throwing a knife at an apple above your head, which do you choose",
  "Which do you prefer",
  "Which do you trust more",
  "You have to give up chocolate forever to have this, which one will you take",
  "Your child will live the next month with",
  "The President of The Universe is now",
  "You'll trust the nuclear codes to",
  "The first thing the aliens witness from humanity is",
];

const DecisionMaker = ({ options, setGlobalResults, goToNextAppState }) => {
  const [weightedTable, setWeightedTable] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [currentPregunta, setCurrentPregunta] = useState(0);
  const [currentPrompt, setCurrentPrompt] = useState(
    prompts[getRandomInt(prompts.length - 1)]
  );
  const [swipeClasses, setSwipeClasses] = useState("");

  const createPreguntas = (weightedValues) => {
    let newPreguntas = [];

    let weightedTableFlex = [...weightedValues];
    weightedTableFlex.forEach((optionA, i) => {
      weightedTableFlex.forEach((optionB) => {
        if (optionB.index !== optionA.index) {
          newPreguntas.push({
            optionA: optionA,
            optionB: optionB,
          });
        }
      });
      weightedTableFlex.splice(i, 1);
    });

    if (newPreguntas.length < 4) {
      newPreguntas = [...newPreguntas, ...newPreguntas, ...newPreguntas];
    }

    setPreguntas(newPreguntas);
  };

  useEffect(() => {
    let weightedValues = [];
    for (let i = 0; i < options.length; i++) {
      const option = { index: i, name: options[i], weight: 0 };
      weightedValues.push(option);
    }

    for (let i = weightedValues.length - 1; i > 0; --i) {
      const j = Math.floor(Math.random() * i);
      const temp = weightedValues[i];
      weightedValues[i] = weightedValues[j];
      weightedValues[j] = temp;
    }

    createPreguntas(weightedValues);
    setWeightedTable(weightedValues);
  }, [options]);

  const chooseOption = (option) => {
    let adjustedTable = [...weightedTable];
    adjustedTable.forEach((choices) => {
      if (choices.index === option.index) {
        ++option.weight;
      }
    });

    setWeightedTable(adjustedTable);
    nextPregunta();
  };

  const nextPregunta = () => {
    if (currentPregunta < preguntas.length - 1) {
      setSwipeClasses("swipeOut");
    } else {
      setGlobalResults(weightedTable);
      goToNextAppState();
    }
  };

  useEffect(() => {
    if (swipeClasses === "swipeOut") {
      setTimeout(() => {
        setCurrentPregunta(currentPregunta + 1);

        let newPrompt = prompts[getRandomInt(prompts.length - 1)];
        while (newPrompt === currentPrompt) {
          newPrompt = prompts[getRandomInt(prompts.length - 1)];
        }

        setCurrentPrompt(newPrompt);
        setSwipeClasses("swipeIn");
      }, 500);
    }
    // eslint-disable-next-line
  }, [swipeClasses]);

  if (preguntas.length > 0) {
    return (
      <div className={`DecisionMaker ${swipeClasses}`}>
        <Pregunta
          pregunta={preguntas[currentPregunta]}
          prompt={currentPrompt}
          chooseOption={chooseOption}
        />
        <MakingDecisionsInstructions />
      </div>
    );
  }
  return <></>;
};

const Pregunta = ({ pregunta, prompt, chooseOption }) => {
  const inputFocus = useRef();

  const chooseA = (e) => {
    e.preventDefault();
    chooseOption(pregunta.optionA);
  };

  const chooseB = (e) => {
    e.preventDefault();
    chooseOption(pregunta.optionB);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 37) {
      chooseOption(pregunta.optionA);
    }
    if (e.keyCode === 39) {
      chooseOption(pregunta.optionB);
    }
  };

  useInterval(() => {
    inputFocus.current.focus();
  }, 1000);

  return (
    <div
      className="pregunta"
      tabIndex="1"
      onKeyUp={handleKeyPress}
      ref={inputFocus}
      autoFocus
    >
      <div className="prompt">{prompt}...?</div>
      <div className="options">
        <span className="option" onClick={chooseA}>
          {pregunta.optionA.name}
        </span>{" "}
        or{" "}
        <span className="option" onClick={chooseB}>
          {pregunta.optionB.name}
        </span>
      </div>
      <div className="select-option">
        <button onClick={chooseA}>
          <FA name="arrow-left " />
        </button>

        <button onClick={chooseB}>
          <FA name="arrow-right " />
        </button>
      </div>
    </div>
  );
};

const MakingDecisionsInstructions = () => {
  return (
    <div className="instructions">
      {"Left Arrow -> Choose 1st Option || Right Arrow -> Choose 2nd Option"}
    </div>
  );
};

export default DecisionMaker;
