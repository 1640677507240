import React, { useState, useEffect, useRef } from "react";
import useInterval from "../useInterval";

const placeholders = [
  "Pizza",
  "Picard",
  "Taco Bell",
  "Hamburgers",
  "Yellow",
  "Spiderman",
  "Team Jacob",
  "Koala Bears",
  "wood",
  "SANS",
  "Bass",
  "Guitar",
  "Piano",
  "Girls",
  "Blue",
  "Green",
];

const getRandomInt = (max) => {
  return Math.floor(Math.random() * (max + 1));
};

const ChoiceAdder = ({ goToNextAppState, setGlobalOptions }) => {
  const goToSelectionStage = (options) => {
    setGlobalOptions(options);
    goToNextAppState();
  };

  return (
    <div className="ChoiceAdder">
      <p className="WelcomeText">Enter an option for your decision...</p>
      <ChoiceInputs goToSelectionStage={goToSelectionStage} />
      <EnteringChoicesInstructions />
    </div>
  );
};

const EnteringChoicesInstructions = () => {
  return (
    <div className="instructions">
      {"Enter -> Add Another Choice || Shift+Enter -> Start Choosing"}
    </div>
  );
};

const ChoiceInputs = ({ goToSelectionStage }) => {
  const [options, setOptions] = useState([]);

  const addOption = (newOption) => {
    const newOptionsList = [...options];
    newOptionsList.push(newOption);
    setOptions(newOptionsList);
  };

  const finishAddingOptions = () => {
    goToSelectionStage(options);
  };

  return (
    <div className="choice-inputs">
      <PreviousInputList options={options} />
      <ChoiceInput
        addOption={addOption}
        finishAddingOptions={finishAddingOptions}
      />
    </div>
  );
};

const PreviousInputList = ({ options }) => {
  const anchor = useRef();

  useEffect(() => {
    anchor.current.scrollIntoView({ behavior: "smooth" });
  }, [options]);

  return (
    <div className="previous-input-list">
      {options.map((option, i) => (
        <PreviousInput option={option} key={i} />
      ))}
      <div ref={anchor}></div>
    </div>
  );
};

const PreviousInput = ({ option }) => {
  return <div className="previous-input">{option}</div>;
};

const ChoiceInput = ({ addOption, finishAddingOptions }) => {
  const [choiceText, setChoiceText] = useState("");
  const [placeholder, setPlaceholder] = useState(
    placeholders[getRandomInt(placeholders.length - 1)]
  );
  const inputRef = useRef();

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setChoiceText(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (e.key === "Enter" && e.shiftKey) {
      finishAddingOptions();
      return;
    }

    if (e.key === "Enter") {
      handleAdd();
    }
  };

  const handleAdd = () => {
    if (choiceText.length > 0) {
      addOption(choiceText);
      setChoiceText("");
    }
  };

  const handleFinish = (e) => {
    e.preventDefault();
    e.stopPropagation();
    finishAddingOptions();
  };

  useInterval(() => {
    inputRef.current.focus();
  }, 1000);

  useInterval(() => {
    setPlaceholder(placeholders[getRandomInt(placeholders.length - 1)]);
  }, 2500);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <label htmlFor="choice-input">
        <input
          type="text"
          name="choice-input"
          className="choice-input"
          placeholder={placeholder}
          onChange={handleChange}
          onKeyUp={handleSubmit}
          value={choiceText}
          ref={inputRef}
          autoFocus
        />
        <br />
      </label>

      <div className="choice-input-actions">
        <button onClick={handleAdd}>Add</button>
        <button onClick={handleFinish}>Finish</button>
      </div>
    </form>
  );
};

export default ChoiceAdder;
