import React, { useEffect } from "react";
import * as API from "../api";

const ResultsView = ({ results, purpose }) => {
  useEffect(() => {
    const decisionLog = {
      decision: purpose,
      options: results.map((option) => {
        return {
          optionText: option.name,
          weight: option.weight,
        };
      }),
    };

    fetch("/DecisionApi/", {
      method: "POST",
      mode: "same-origin",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ decisionLog: decisionLog }),
    }).then((response) => {
      console.log("response");
    });
    //API.saveResultsToDb(decisionLog);
  }, []);

  return (
    <div className="ResultsView">
      Based on your answers, you should choose{" "}
      <span className="best-choice">
        {results.sort((a, b) => b.weight - a.weight)[0].name}
      </span>{" "}
      for the choice of <span className="best-choice">{purpose}</span>.
      <ViewingResultsInstructions />
    </div>
  );
};

const ViewingResultsInstructions = () => {
  return <div className="instructions">{"CTRL + R to Start Over"}</div>;
};

export default ResultsView;
