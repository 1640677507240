import React, { useState, useEffect } from "react";
import "./App.css";
import "./font-awesome/css/font-awesome.min.css";

import SettingPurpose from "./components/SettingPurpose";
import ChoiceAdder from "./components/ChoiceAdder";
import DecisionMaker from "./components/DecisionMaker";
import ResultsView from "./components/ResultsView";

const SETTING_PURPOSE = 0;
const ADDING_CHOICES = 1;
const MAKING_DECISIONS = 2;
const DISPLAYING_RESULTS = 3;

function App() {
  const [appState, setAppState] = useState(SETTING_PURPOSE);
  const [purpose, setPurpose] = useState("");
  const [options, setOptions] = useState([]);
  const [results, setResults] = useState([]);

  const goToNextAppState = () => {
    setAppState(appState + 1);
  };

  const setGlobalPurpose = (newPurpose) => {
    setPurpose(newPurpose);
  };

  const setGlobalOptions = (newOptions) => {
    setOptions(newOptions);
  };

  const setGlobalResults = (newResults) => {
    setResults(newResults);
  };

  const resetApp = (e) => {
    if (e.keyCode === 82 && e.ctrlKey) {
      console.log("reset...");
      setAppState(ADDING_CHOICES);
      setOptions([]);
      setResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", resetApp);
  }, []);

  return (
    <div className="App">
      {appState === SETTING_PURPOSE && (
        <SettingPurpose
          goToNextAppState={goToNextAppState}
          setGlobalPurpose={setGlobalPurpose}
        />
      )}
      {appState === ADDING_CHOICES && (
        <ChoiceAdder
          goToNextAppState={goToNextAppState}
          setGlobalOptions={setGlobalOptions}
        />
      )}
      {appState === MAKING_DECISIONS && (
        <DecisionMaker
          options={options}
          setGlobalResults={setGlobalResults}
          goToNextAppState={goToNextAppState}
        />
      )}
      {appState === DISPLAYING_RESULTS && (
        <ResultsView results={results} purpose={purpose} />
      )}
    </div>
  );
}

export default App;
