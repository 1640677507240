import React, { useState, useRef } from "react";
import useInterval from "../useInterval";

const placeholders = [
  "what to have for dinner",
  "which superhero is best",
  "what time to go on a date",
  "the best programming language",
  "the funniest TV show of all time",
  "the best Trek Captain",
  "the best Star Wars",
  "if Han, Greedo, or Obi-Wan shot first",
];

const getRandomInt = (max) => {
  return Math.floor(Math.random() * (max + 1));
};

const SettingPurpose = ({ setGlobalPurpose, goToNextAppState }) => {
  const setPurpose = (purpose) => {
    setGlobalPurpose(purpose);
    goToNextAppState();
  };

  return (
    <div className="SettingPurpose">
      Please enter what you're trying to decide on...
      <PurposeInput setPurpose={setPurpose} />
      <SettingPurposeInstructions />
    </div>
  );
};

const PurposeInput = ({ setPurpose }) => {
  const [purposeText, setPurposeText] = useState("");
  const [placeholder, setPlaceholder] = useState(
    placeholders[getRandomInt(placeholders.length - 1)]
  );
  const inputRef = useRef();

  useInterval(() => {
    setPlaceholder(placeholders[getRandomInt(placeholders.length - 1)]);
  }, 2500);

  const handleChange = (e) => {
    setPurposeText(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.key === "Enter") {
      handleContinue();
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (purposeText.length > 0) {
      setPurpose(purposeText);
      setPurposeText("");
    }
  };

  useInterval(() => {
    inputRef.current.focus();
  }, 1000);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <label htmlFor="choice-input">
        <input
          type="text"
          name="choice-input"
          className="choice-input"
          placeholder={placeholder}
          onChange={handleChange}
          onKeyUp={handleSubmit}
          value={purposeText}
          ref={inputRef}
          autoFocus
        />
        <br />
      </label>
      <div className="purpose-input-actions">
        <button onClick={handleContinue}>Continue</button>
      </div>
    </form>
  );
};

const SettingPurposeInstructions = () => {
  return <div className="instructions">{"Enter -> Continue"}</div>;
};

export default SettingPurpose;
